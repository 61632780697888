import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import "./CarItem.css";
import { Link } from "react-router-dom";

const CarItem = ({ car }) => {
    return (

        <Card id="car-item" className="m-auto">
            <Link to={`/mobilityModel/${car.id}`}>
            <Card.Img variant="top" src={car.thumbnailImage} className="car-item-image" />
            <Card.Body>
                <Card.Title><span>{car.modelName}</span> </Card.Title>
                <Card.Text><span>연식 : {car.year}년</span></Card.Text>
                <Card.Text><span>가격 : ${car.price.toLocaleString()}</span></Card.Text>
            </Card.Body>
            </Link>
        </Card>

    );
};

export default CarItem;
