import axios from 'axios';
import { checkTokenExpiration } from '../utils/CheckTokenExpiration';
import { store } from '../store/Store.js';
import i18next from 'i18next'; // i18next 임포트

// Axios 인스턴스 생성
const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

// 요청 인터셉터 설정
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            // 요청 전에 토큰 만료 여부 확인
            checkTokenExpiration(token, store.dispatch);
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        // i18next로 설정된 언어 코드 추가
        const currentLanguage = i18next.language || 'en'; // 기본값을 'en'으로 설정
        config.headers['Accept-Language'] = currentLanguage;

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default api;
