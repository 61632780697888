import React, {useState, useEffect} from "react";
import axios from "../utils/axios";
import CarList from "../components/car/CarList";
import "./MainPage.css"
import Hero from "../components/hero/Hero";

const MainPage = () => {
    const [cars, setCars] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        // 최근 자동차 정보 가져오기
        const fetchRecentCars = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/models`, {
                    params: {
                        page: 0,
                        size: 5,
                    },
                });
                setCars(response.data.content); // API 응답의 내용 중 자동차 리스트 설정
            } catch (err) {
                setError("자동차 정보를 불러오는 데 실패했습니다.");
            } finally {
                setLoading(false);
            }
        };

        fetchRecentCars();
    }, []);

    // if (loading) return <p>로딩 중...</p>;
    // if (error) return <p>오류 발생: {error}</p>;

    return (
        <>
            <Hero/>
            {/*<CarList cars={cars}/>*/}
        </>

    );
};

export default MainPage;
