import React, { useEffect, useState } from 'react';
import axios from "../../utils/axios";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CustomPagenation from "../../utils/CustomPagenation";
import "./ContactList.css";

const ContactList = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [contactPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const [contactList, setContactList] = useState([]);
    const [page, setPage] = useState({});
    const [activePage, setActivePage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState({});
    const handleActivePage = (page) => {
        setActivePage(page);
    };

    // 접거나 펼치는 이벤트 핸들러
    const toggleExpand = (id) => {
        setExpanded((prevState) => ({
            ...prevState,
            [id]: !prevState[id], // 클릭한 ID의 상태를 반전
        }));
    };

    useEffect(() => {
        fetchContact(searchQuery);
    }, [activePage, contactPerPage, searchQuery]);

    const fetchContact = async (query = "") => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/api/contact`, {
                params: {
                    page: activePage,
                    size: contactPerPage,
                    query: query,
                },
            });

            setContactList(response.data.content || []);
            setPage(response.data.page || {});
        } catch (error) {
            console.error("Failed to fetch contact data:", error);
            setContactList([]);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container fluid="md" id="contact-list-container">
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <h1>{t("contact.title")}</h1>
                    <Row>
                        <Col><strong>{t("contact.name")}</strong></Col>
                        <Col><strong>{t("contact.email")}</strong></Col>
                        <Col><strong>{t("contact.inquiryContent")}</strong></Col>
                    </Row>
                    {Array.isArray(contactList) && contactList.map((contact) => (
                        <Row
                            key={contact.id}
                            className="contact-row"
                            onClick={() => toggleExpand(contact.id)} // 행 클릭 시 펼치기/접기
                            style={{ cursor: "pointer" }}
                        >
                            <Col>{contact.name}</Col>
                            <Col>{contact.email}</Col>
                            <Col>
                                {expanded[contact.id]
                                    ? contact.inquiryContent
                                    : contact.inquiryContent.length > 20
                                        ? `${contact.inquiryContent.slice(0, 20)}...`
                                        : contact.inquiryContent}
                            </Col>
                            </Row>
                    ))}
                    <CustomPagenation page={page} handleActivePage={handleActivePage} />
                </>
            )}
        </Container>
    );
};

export default ContactList;
