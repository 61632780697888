import React, { useEffect, useState } from "react";
import Pagination from "react-bootstrap/Pagination";

const CustomPagenation = ({ page, handleActivePage }) => {
    const [pageItems, setPageItems] = useState([]);

    useEffect(() => {
        if (!page || !page.totalPages) return;

        const currentPage = page.number + 1; // `page.number` is 0-based
        const startNum = Math.max(1, currentPage - 5);
        const endNum = Math.min(page.totalPages, currentPage + 5);

        const paginationItems = Array.from({ length: endNum - startNum + 1 }, (_, i) => {
            const number = startNum + i;
            return (
                <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => handleActivePage(number)}
                >
                    {number}
                </Pagination.Item>
            );
        });

        setPageItems(paginationItems);
    }, [page, handleActivePage]);

    if (!page || !page.totalPages) return null;

    return (
        <Pagination>
            {page.number > 0 && (
                <>
                    <Pagination.First onClick={() => handleActivePage(1)} />
                    <Pagination.Prev onClick={() => handleActivePage(page.number)} />
                </>
            )}
            {pageItems}
            {page.number + 1 < page.totalPages && (
                <>
                    <Pagination.Next onClick={() => handleActivePage(page.number + 2)} />
                    <Pagination.Last onClick={() => handleActivePage(page.totalPages)} />
                </>
            )}
        </Pagination>
    );
};

export default CustomPagenation;
