import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Table,
  Pagination,
  Tab,
  Nav,
} from "react-bootstrap";
import axios from "../../utils/axios";
import { FaStar, FaSearch } from "react-icons/fa";
import "./BuyerListPage.css";

const BuyerListPage = () => {
  const [buyers, setBuyers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [favorites, setFavorites] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [activeCountry, setActiveCountry] = useState("All");
  const [countries, setCountries] = useState([]); // 국가 목록 초기값을 빈 배열로 설정
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchCountries();
    fetchBuyers();
  }, [currentPage, activeCountry]);

  const fetchCountries = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/countries`);
      setCountries(response.data || []); // 응답이 배열이 아닌 경우 빈 배열로 설정
    } catch (error) {
      console.error("Error fetching countries:", error);
      setCountries([]); // 오류가 발생해도 빈 배열로 설정
    }
  };

  const fetchBuyers = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/buyers`, {
        params: {
          country: activeCountry !== "All" ? activeCountry : "",
          page: currentPage,
          size: 10,
        },
      });
      setBuyers(response.data.content || []);
      setTotalPages(response.data.totalPages || 0);
    } catch (error) {
      console.error("Error fetching buyers:", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredBuyers = buyers.filter(
      (buyer) =>
          (buyer.companyName &&
              buyer.companyName.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (buyer.country &&
              buyer.country.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const toggleFavorite = (id) => {
    setFavorites((prevFavorites) =>
        prevFavorites.includes(id)
            ? prevFavorites.filter((favId) => favId !== id)
            : [...prevFavorites, id]
    );
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCountrySelect = (country) => {
    setActiveCountry(country);
    setCurrentPage(0); // 국가 변경 시 첫 페이지로 이동
  };

  const handlePageJump = (e) => {
    e.preventDefault();
    const pageInput = parseInt(e.target.elements.pageInput.value, 10) - 1;
    if (pageInput >= 0 && pageInput < totalPages) {
      setCurrentPage(pageInput);
    }
  };

  const getPaginationItems = () => {
    const totalPageItems = Math.min(totalPages, 10);
    const startPage = Math.max(currentPage - Math.floor(totalPageItems / 2), 0);
    const endPage = Math.min(startPage + totalPageItems - 1, totalPages - 1);

    return Array.from({ length: endPage - startPage + 1 }, (_, i) => {
      const pageIndex = startPage + i;
      return (
          <Pagination.Item
              key={pageIndex}
              active={pageIndex === currentPage}
              onClick={() => handlePageChange(pageIndex)}
          >
            {pageIndex + 1}
          </Pagination.Item>
      );
    });
  };

  return (
      <Container className="mt-4">
        <Row className="mb-3">
          <Col md={12}>
            <h2>Buyer List</h2>
          </Col>
        </Row>

        <Tab.Container activeKey={activeCountry} onSelect={handleCountrySelect}>
          <Nav variant="tabs" className="custom-tabs">
            <Nav.Item>
              <Nav.Link eventKey="All">All</Nav.Link>
            </Nav.Item>
            {Array.isArray(countries) &&
                countries.map((country) => (
                    <Nav.Item key={country}>
                      <Nav.Link eventKey={country}>{country}</Nav.Link>
                    </Nav.Item>
                ))}
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey={activeCountry}>
              <Table striped bordered hover>
                <thead>
                <tr>
                  <th>Company Name</th>
                  <th>Country</th>
                  <th>Address</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Website</th>
                  <th>Favorite</th>
                </tr>
                </thead>
                <tbody>
                {filteredBuyers.length > 0 ? (
                    filteredBuyers.map((buyer) => (
                        <tr key={buyer.id}>
                          <td>{buyer.companyName || "N/A"}</td>
                          <td>{buyer.country || "N/A"}</td>
                          <td>{buyer.address || "N/A"}</td>
                          <td>{buyer.email || "N/A"}</td>
                          <td>{buyer.phoneNumber || "N/A"}</td>
                          <td>
                            {buyer.website ? (
                                <a href={buyer.website}>{buyer.website}</a>
                            ) : (
                                "N/A"
                            )}
                          </td>
                          <td>
                            <Button
                                variant="link"
                                onClick={() => toggleFavorite(buyer.id)}
                                className="p-0"
                                style={{
                                  color: favorites.includes(buyer.id)
                                      ? "gold"
                                      : "gray",
                                }}
                            >
                              <FaStar />
                            </Button>
                          </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                      <td colSpan="7">No buyers found.</td>
                    </tr>
                )}
                </tbody>
              </Table>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>

        <Pagination className="mt-3">
          <Pagination.First onClick={() => handlePageChange(0)} />
          <Pagination.Prev
              onClick={() => handlePageChange(Math.max(currentPage - 1, 0))}
          />
          {getPaginationItems()}
          <Pagination.Next
              onClick={() =>
                  handlePageChange(Math.min(currentPage + 1, totalPages - 1))
              }
          />
          <Pagination.Last onClick={() => handlePageChange(totalPages - 1)} />
        </Pagination>

        <Form
            inline
            onSubmit={handlePageJump}
            className="d-flex justify-content-center mt-3"
        >
          <Form.Control
              type="number"
              name="pageInput"
              min="1"
              max={totalPages}
              placeholder="Go to page"
              style={{
                width: "120px",
                marginRight: "10px",
              }}
          />
          <Button type="submit" variant="outline-primary">
            Go
          </Button>
        </Form>
      </Container>
  );
};

export default BuyerListPage;
