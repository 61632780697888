import React, {useState} from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
import {useDispatch, useSelector} from "react-redux";
import {logout, handleShowLogin} from "../../store/Store";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser, faGlobe, faTachometerAlt, faDoorOpen} from "@fortawesome/free-solid-svg-icons";
import {getDecodedToken} from "../../utils/jwtUtils";
import styles from './NavBar.module.css';  // CSS 모듈 import

function NavBar() {
    const {t, i18n} = useTranslation();
    let auth = useSelector((state) => state.auth);
    let dispatch = useDispatch();


    const isAdmin = getDecodedToken()?.role?.includes("ADMIN");

    const handleLogout = () => {
        dispatch(logout());
        window.location.reload();
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <Navbar expand="lg" sticky="top" className={styles.navbar}>
            <Container>
                <Navbar.Brand as={Link} to="/" className={styles.navbarBrand}>
                    Win-Win
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className={styles.navbarToggler}>
                    <span className={styles.navbarTogglerIcon}></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav" className={styles.navbarCollapse}>
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/product" className={styles.navLink}>
                            {t("navbar.product")}
                        </Nav.Link>
                        <Nav.Link as={Link} to="/post" className={styles.navLink}>
                            {t("navbar.community")}
                        </Nav.Link>
                        <Nav.Link as={Link} to="/contact" className={styles.navLink}>
                            {t("navbar.contact")}
                        </Nav.Link>
                        {/*<Nav.Link as={Link} to="/about-us" className={styles.navLink}>*/}
                        {/*    About-Us*/}
                        {/*</Nav.Link>*/}
                        <Nav.Link as={Link} to="/qna" className={styles.navLink}>
                            {t("navbar.qna")}
                        </Nav.Link>

                        {isAdmin && (
                            <Nav.Link as={Link} to="/admin/dashboard" className={styles.navLink}>
                                <FontAwesomeIcon icon={faTachometerAlt}/> Admin Dashboard
                            </Nav.Link>
                        )}
                    </Nav>
                    <Nav className="ms-auto">
                        {auth ? (
                            <Dropdown className={styles.userDropdown}>
                                <Dropdown.Toggle variant="secondary" id="user-dropdown">
                                    <FontAwesomeIcon icon={faUser}/>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to="/user/profile" className={styles.dropdownLink} >
                                            <FontAwesomeIcon icon={faUser} className={styles.icon}/> <span>{t("navbar.profile")}</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={handleLogout} className={styles.dropdownLink}>
                                        <FontAwesomeIcon icon={faDoorOpen} className={styles.icon}/> <span>{t("navbar.logout")}</span>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        ) : (
                            <Nav.Link style={{color: "white"}} onClick={() => dispatch(handleShowLogin())} className={styles.navLink}>
                                <FontAwesomeIcon icon={faUser}/> {t("navbar.login")}
                            </Nav.Link>
                        )}
                        <Dropdown>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic" className={styles.navLink}>
                                <FontAwesomeIcon icon={faGlobe}/> {t("navbar.language")}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => changeLanguage("en")}>English (English)</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeLanguage("ko")}>한국어 (Korean)</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeLanguage("zh-CN")}>简体中文 (Simplified Chinese)</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeLanguage("es")}>Español (Spanish)</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeLanguage("ar")}>العربية (Arabic)</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeLanguage("ru")}>Русский (Russian)</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeLanguage("fr")}>Français (French)</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeLanguage("pt")}>Português (Portuguese)</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeLanguage("ja")}>日本語 (Japanese)</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeLanguage("hi")}>हिंदी (Hindi)</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeLanguage("ur")}>اردو (Urdu)</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>


        </Navbar>
    );
}

export default NavBar;
