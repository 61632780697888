import React, { useState, useCallback, useEffect } from "react";
import {
  Tabs,
  Tab,
  Form,
  Button,
  Image,
  Container,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import { useDropzone } from "react-dropzone";
// import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../utils/axios";
import { useTranslation } from "react-i18next"; // i18n 추가
import styles from "./MobilityModelUpload.module.css"; // 모듈 CSS 가져오기

const CarUploadPage = () => {
  const { t } = useTranslation(); // i18n 훅 사용
  const { id } = useParams();
  const [seriesOptions, setSeriesOptions] = useState([]);
  const [images, setImages] = useState([]);
  const [thumbnailIndex, setThumbnailIndex] = useState(0);
  const [carInfo, setCarInfo] = useState({
    modelName: "",
    price: "",
    year: "",
    stockStatus: t("carUpload.stockStatusOptions.inStock"),
  });

  const [basicInfo, setBasicInfo] = useState({
    vehicleType: "",
    powerType: "",
    timeToMarket: "",
    vehicleStructure: "",
    overallDimensions: "",
    containerSize: "",
    wheelBase: "",
    curbWeight: "",
    maxFullLoadWeight: "",
  });

  const [engineInfo, setEngineInfo] = useState({
    displacementMl: "",
    displacementL: "",
    horsepowerPs: "",
  });

  const [electricMotorInfo, setElectricMotorInfo] = useState({
    motorTypeKW: "",
    motorHorsepowerPs: "",
    totalMotorTorque: "",
    batteryType: "",
    batteryBrand: "",
    necdPureElectricRange: "",
    batteryCapacity: "",
    powerConsumption: "",
    quickCharge: "",
    slowCharge: "",
    percentageOfFastCharge: "",
  });

  const [chassisSteeringInfo, setChassisSteeringInfo] = useState({
    driveMode: "",
    fourWheelDrive: "",
  });

  const [transmissionInfo, setTransmissionInfo] = useState({
    numberOfGears: "",
  });

  const [additionalTabs, setAdditionalTabs] = useState([]);
  const [activeKey, setActiveKey] = useState("basic");
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [deletedImages, setDeletedImages] = useState([]);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchCarData = async (carId) => {
    try {
      const response = await axios.get(`${apiUrl}/api/models/${id}`);
      const carData = response.data;

      const newAdditionalTabs = [];

      carData.parameters.forEach((param) => {
        switch (param.parameterName) {
          case "vehicleType":
          case "powerType":
          case "timeToMarket":
          case "vehicleStructure":
          case "overallDimensions":
          case "containerSize":
          case "wheelBase":
          case "curbWeight":
          case "maxFullLoadWeight":
            setBasicInfo((prev) => ({
              ...prev,
              [param.parameterName]: param.parameterValue,
            }));
            break;
          case "displacementMl":
          case "displacementL":
          case "horsepowerPs":
            setEngineInfo((prev) => ({
              ...prev,
              [param.parameterName]: param.parameterValue,
            }));
            break;
          case "motorTypeKW":
          case "motorHorsepowerPs":
          case "totalMotorTorque":
          case "batteryType":
          case "batteryBrand":
          case "necdPureElectricRange":
          case "batteryCapacity":
          case "powerConsumption":
          case "quickCharge":
          case "slowCharge":
          case "percentageOfFastCharge":
            setElectricMotorInfo((prev) => ({
              ...prev,
              [param.parameterName]: param.parameterValue,
            }));
            break;
          case "driveMode":
          case "fourWheelDrive":
            setChassisSteeringInfo((prev) => ({
              ...prev,
              [param.parameterName]: param.parameterValue,
            }));
            break;
          case "numberOfGears":
            setTransmissionInfo((prev) => ({
              ...prev,
              [param.parameterName]: param.parameterValue,
            }));
            break;
          default:
            let existingTab = newAdditionalTabs.find(
                (tab) => tab.title === param.category
            );

            if (existingTab) {
              existingTab.data[param.parameterName] = param.parameterValue;
              existingTab.fieldNames[param.parameterName] = param.parameterName;
            } else {
              newAdditionalTabs.push({
                key: `customTab${newAdditionalTabs.length + 1}`,
                title: param.category,
                data: { [param.parameterName]: param.parameterValue },
                fieldNames: { [param.parameterName]: param.parameterName },
              });
            }
            break;
        }
      });

      setAdditionalTabs(newAdditionalTabs);

      setCarInfo({
        modelName: carData.modelName || "",
        price: carData.price || "",
        year: carData.year || "",
        stockStatus: carData.stockStatus || t("carUpload.stockStatusOptions.inStock"),
        seriesId: carData.series?.id || "",
      });

      setImages(
          carData.images.map((image, index) => ({
            id: image.id,
            url: image.imageUrl,
            isExisting: true,
          }))
      );

      setThumbnailIndex(carData.thumbnailIndex || 0);
    } catch (error) {
      console.error("Error fetching car data:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchCarData(id);
    }
  }, [id]);

  useEffect(() => {
    axios
        .get(`${apiUrl}/api/series`)
        .then((response) => {
          const data = response.data;
          if (Array.isArray(data.content)) {
            setSeriesOptions(data.content);
          } else {
            setSeriesOptions([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching series data:", error);
          setSeriesOptions([]);
        });
  }, []);

  const onDrop = useCallback(
      (acceptedFiles) => {
        const imagePreviews = acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              fileName: file.name,
            })
        );

        setImages((prevImages) => [...prevImages, ...imagePreviews]);
      },
      [setImages]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/gif": [],
      "image/bmp": [],
      "image/webp": [],
    },
    multiple: true,
    onDropRejected: () => {
      alert(t("carUpload.imageUploadError"));
    },
  });

  const handleThumbnailSelect = (index) => {
    setThumbnailIndex(index);
  };

  const handleDeleteImage = (image, index) => {
    setImages((prevImages) =>
        prevImages.map((img, i) =>
            i === index ? { ...img, isDeleted: true } : img
        )
    );
    setDeletedImages((prevDeleted) => [...prevDeleted, image.url]);
    if (thumbnailIndex === index) {
      setThumbnailIndex(0);
    }
  };

  const handleRestoreImage = (index) => {
    setImages((prevImages) =>
        prevImages.map((img, i) =>
            i === index ? { ...img, isDeleted: false } : img
        )
    );
    setDeletedImages((prevDeleted) =>
        prevDeleted.filter((url) => url !== images[index].url)
    );
  };

  const handleInputChange = (e, stateSetter) => {
    const { name, value } = e.target;
    stateSetter((prev) => ({ ...prev, [name]: value }));
  };

  const handleAdditionalTabInputChange = (e, tabKey) => {
    const { name, value } = e.target;
    setAdditionalTabs((prevTabs) =>
        prevTabs.map((tab) =>
            tab.key === tabKey
                ? {
                  ...tab,
                  data: { ...tab.data, [name]: value },
                }
                : tab
        )
    );
  };

  const handleStockStatusChange = (e) => {
    const { value } = e.target;
    setCarInfo((prev) => ({ ...prev, stockStatus: value }));
  };

  const handleTabTitleChange = (e, tabKey) => {
    const { value } = e.target;
    setAdditionalTabs((prevTabs) =>
        prevTabs.map((tab) =>
            tab.key === tabKey ? { ...tab, title: value } : tab
        )
    );
  };

  const handleFieldNameChange = (e, tabKey, fieldKey) => {
    const { value } = e.target;
    setAdditionalTabs((prevTabs) =>
        prevTabs.map((tab) =>
            tab.key === tabKey
                ? {
                  ...tab,
                  fieldNames: {
                    ...tab.fieldNames,
                    [fieldKey]: value,
                  },
                }
                : tab
        )
    );
  };

  const handleAddField = (tabKey) => {
    setAdditionalTabs((prevTabs) =>
        prevTabs.map((tab) => {
          if (tab.key === tabKey) {
            const newFieldKey = `customField${
                Object.keys(tab.fieldNames).length + 1
            }`;
            return {
              ...tab,
              fieldNames: {
                ...tab.fieldNames,
                [newFieldKey]: `New Field ${
                    Object.keys(tab.fieldNames).length + 1
                }`,
              },
              data: {
                ...tab.data,
                [newFieldKey]: "",
              },
            };
          }
          return tab;
        })
    );
  };

  const handleDeleteField = (tabKey, fieldKey) => {
    setAdditionalTabs((prevTabs) =>
        prevTabs.map((tab) => {
          if (tab.key === tabKey) {
            const newFieldNames = { ...tab.fieldNames };
            const newData = { ...tab.data };
            delete newFieldNames[fieldKey];
            delete newData[fieldKey];
            return {
              ...tab,
              fieldNames: newFieldNames,
              data: newData,
            };
          }
          return tab;
        })
    );
  };

  const checkForDuplicateFields = () => {
    const tabNames = new Set();

    for (let tab of additionalTabs) {
      if (tabNames.has(tab.title)) {
        setErrorMessage(t("carUpload.errorMessages.duplicateTabName"));
        return false;
      }
      tabNames.add(tab.title);

      const fieldNames = new Set();
      for (let fieldName of Object.values(tab.fieldNames)) {
        if (fieldNames.has(fieldName)) {
          setErrorMessage(t("carUpload.errorMessages.duplicateFieldName"));
          return false;
        }
        fieldNames.add(fieldName);
      }
    }
    setErrorMessage(null);
    return true;
  };

  const resetForm = () => {
    setImages([]);
    setThumbnailIndex(0);
    setCarInfo({
      modelName: "",
      price: "",
      year: "",
      stockStatus: t("carUpload.stockStatusOptions.inStock"),
    });
    setBasicInfo({
      vehicleType: "",
      powerType: "",
      timeToMarket: "",
      vehicleStructure: "",
      overallDimensions: "",
      containerSize: "",
      wheelBase: "",
      curbWeight: "",
      maxFullLoadWeight: "",
    });
    setEngineInfo({
      displacementMl: "",
      displacementL: "",
      horsepowerPs: "",
    });
    setElectricMotorInfo({
      motorTypeKW: "",
      motorHorsepowerPs: "",
      totalMotorTorque: "",
      batteryType: "",
      batteryBrand: "",
      necdPureElectricRange: "",
      batteryCapacity: "",
      powerConsumption: "",
      quickCharge: "",
      slowCharge: "",
      percentageOfFastCharge: "",
    });
    setChassisSteeringInfo({
      driveMode: "",
      fourWheelDrive: "",
    });
    setTransmissionInfo({
      numberOfGears: "",
    });
    setAdditionalTabs([]);
    setActiveKey("basic");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!checkForDuplicateFields()) {
      return;
    }

    const filterEmptyFields = (categoryName, data) =>
        Object.entries(data)
            .filter(([_, value]) => value.trim() !== "")
            .map(([key, value]) => ({
              category: categoryName,
              parameterName: key,
              parameterValue: value,
            }));

    const parameters = [
      ...filterEmptyFields("BasicInfo", basicInfo),
      ...filterEmptyFields("EngineInfo", engineInfo),
      ...filterEmptyFields("ElectricMotorInfo", electricMotorInfo),
      ...filterEmptyFields("ChassisSteeringInfo", chassisSteeringInfo),
      ...filterEmptyFields("TransmissionInfo", transmissionInfo),
      ...additionalTabs.flatMap((tab) =>
          Object.entries(tab.fieldNames).map(([key, fieldName]) => ({
            category: tab.title,
            parameterName: fieldName,
            parameterValue: tab.data[key]?.trim() || "",
          }))
      ),
    ];

    const formData = new FormData();
    formData.append("modelName", carInfo.modelName);
    formData.append("price", carInfo.price);
    formData.append("year", carInfo.year);
    formData.append("seriesId", carInfo.seriesId);
    formData.append("parameters", JSON.stringify(parameters));
    formData.append("thumbnailIndex", thumbnailIndex);
    formData.append("stockStatus", carInfo.stockStatus);

    const imageDTOs = [...images].map((image, index) => ({
      id: image.id || null,
      imageName: !image.isExisting ? image.fileName : "",
      imageUrl: image.url || "",
      isThumbnail: thumbnailIndex === index,
      imageStatus: image.isDeleted ? "DELETED" : image.isExisting ? "UNCHANGED" : "NEW",
    }));

    formData.append("images", new Blob([JSON.stringify(imageDTOs)], { type: "application/json" }));

    [...images].forEach((image, index) => {
      if (!image.isExisting && image instanceof File) {
        formData.append("imageFiles", image, image.name);
      }
    });

    const requestConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    if (id) {
      axios
          .put(`${apiUrl}/api/models/${id}`, formData, requestConfig)
          .then((response) => {
            alert(t("carUpload.successUpdate"));
            resetForm();
            setTimeout(() => {
              navigate("/");
            }, 2000);
          })
          .catch((error) => {
            console.error("There was an error uploading the data:", error);
          });
    } else {
      axios
          .post(`${apiUrl}/api/models`, formData, requestConfig)
          .then((response) => {
            alert(t("carUpload.successUpload"));
            resetForm();
            setTimeout(() => {
              navigate("/");
            }, 2000);
          })
          .catch((error) => {
            console.error("There was an error uploading the data:", error);
          });
    }
  };

  const handleAddTab = () => {
    const newKey = `customTab${additionalTabs.length + 1}`;
    const newTab = {
      key: newKey,
      title: t("carUpload.customTabTitle", { count: additionalTabs.length + 1 }),
      data: {},
      fieldNames: {
        customField1: t("carUpload.customField", { count: 1 }),
        customField2: t("carUpload.customField", { count: 2 }),
      },
    };
    setAdditionalTabs([...additionalTabs, newTab]);
    setActiveKey(newKey);
  };

  const handleDeleteTab = (tabKey) => {
    setAdditionalTabs((prevTabs) => prevTabs.filter((tab) => tab.key !== tabKey));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  };

  return (
      <div className={`${styles.containerStyle} mt-5 p-4 w-75 m-auto`}>
        <h1 className={`mb-4 ${styles.tabTitle}`}>{t("carUpload.pageTitle")}</h1>

        {successMessage && (
            <Alert
                variant="success"
                onClose={() => setSuccessMessage("")}
                dismissible
            >
              {successMessage}
            </Alert>
        )}

        {errorMessage && (
            <Alert
                variant="danger"
                onClose={() => setErrorMessage(null)}
                dismissible
            >
              {errorMessage}
            </Alert>
        )}

        <Form.Group className="mb-4">
          <Form.Label className={styles.formLabel}>
            {t("carUpload.seriesSelectLabel")}
          </Form.Label>
          <Form.Control
              as="select"
              name="seriesId"
              value={carInfo.seriesId}
              onChange={(e) => handleInputChange(e, setCarInfo)}
              className={styles.formControl}
          >
            <option value="">{t("carUpload.seriesSelectPlaceholder")}</option>
            {Array.isArray(seriesOptions) &&
                seriesOptions.map((series) => (
                    <option key={series.id} value={series.id}>
                      {series.seriesName} - {series.brandName}
                    </option>
                ))}
          </Form.Control>
        </Form.Group>

        <h2 className={styles.commonInfoTitle}>{t("carUpload.commonInfoTitle")}</h2>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className={styles.formLabel}>
                {t("carUpload.modelName")}
              </Form.Label>
              <Form.Control
                  type="text"
                  name="modelName"
                  value={carInfo.modelName}
                  onChange={(e) => handleInputChange(e, setCarInfo)}
                  className={styles.formControl}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className={styles.formLabel}>
                {t("carUpload.price")}
              </Form.Label>
              <Form.Control
                  type="text"
                  name="price"
                  value={carInfo.price}
                  onChange={(e) => handleInputChange(e, setCarInfo)}
                  className={styles.formControl}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className={styles.formLabel}>
                {t("carUpload.year")}
              </Form.Label>
              <Form.Control
                  type="text"
                  name="year"
                  value={carInfo.year}
                  onChange={(e) => handleInputChange(e, setCarInfo)}
                  className={styles.formControl}
              />
            </Form.Group>
          </Col>
        </Row>

        <div {...getRootProps()} className={styles.imageUploadSection}>
          <input {...getInputProps()} />
          <p>{t("carUpload.imageUploadText")}</p>
        </div>

        <Container className="d-flex flex-wrap">
          {images.map((image, index) => (
              <div
                  key={index}
                  style={{ position: "relative", margin: "10px" , width:"150px"}}
              >
                <Image
                    src={image.isExisting ? image.url : image.preview}
                    className={index === thumbnailIndex ? styles.imageThumbnail : styles.imagePreview}
                />
                {/*{image.isDeleted && (*/}
                {/*    <div className={styles.imageOverlay}>X</div>*/}
                {/*)}*/}
                {image.isDeleted ? (
                    <Button
                        variant="success"
                        size="sm"
                        onClick={() => handleRestoreImage(index)}
                    >
                      {t("carUpload.restoreImage")}
                    </Button>
                ) : (
                    <div className="d-flex flex-row">
                      <Button
                          variant="primary"
                          size="sm"
                          onClick={() => handleThumbnailSelect(index)}
                      >
                        <i className="bi bi-card-image"></i>
                        {/*{t("carUpload.selectThumbnail")}*/}
                      </Button>
                      <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleDeleteImage(image, index)}
                      >
                        <i className="bi bi-trash3-fill"></i>
                        {/*{t("carUpload.deleteImage")}*/}
                      </Button>
                    </div>
                )}
              </div>
          ))}
        </Container>

        <Form onSubmit={handleSubmit} id={styles.parameterForm}>
          <Tabs
              activeKey={activeKey}
              onSelect={(k) => k !== "add" && setActiveKey(k)}
              className="mb-3"
          >
            {additionalTabs.map((tab) => (
                <Tab
                    key={tab.key}
                    eventKey={tab.key}
                    title={
                      <div style={{ display: "flex", alignItems: "center", height:"100%", width:"100%"}}>
                        <Form.Control
                            type="text"
                            value={tab.title}
                            onChange={(e) => handleTabTitleChange(e, tab.key)}
                            onKeyPress={handleKeyPress}
                            style={{
                              backgroundColor: "#e9ecef",
                              border: "none",
                              textAlign: "center",
                              fontWeight: "bold",
                              height: "100%",
                              width: "100%",
                              margin: "0",
                              borderRadius:"0"
                            }}
                        />
                        <Button
                            size="sm"
                            onClick={() => handleDeleteTab(tab.key)}
                            style={{ height: "100%" , width:"24px", borderRadius:"0"}}
                        >
                          X
                        </Button>
                      </div>
                    }
                >
                  {Object.entries(tab.fieldNames).map(([fieldKey, fieldLabel]) => (
                      <Form.Group className="mb-3" key={fieldKey}>
                        <Row className="align-items-center">
                          <Col>
                            <Form.Control
                                type="text"
                                value={fieldLabel}
                                onChange={(e) => handleFieldNameChange(e, tab.key, fieldKey)}
                                onKeyPress={handleKeyPress}
                                className={styles.formControl}
                            />
                          </Col>
                          <Col>
                            <Form.Control
                                type="text"
                                name={fieldKey}
                                value={tab.data[fieldKey] || ""}
                                onChange={(e) => handleAdditionalTabInputChange(e, tab.key)}
                                className={styles.formControl}
                            />
                          </Col>
                          <Col xs="auto">
                            <Button
                                className={styles.deleteFieldButton}
                                onClick={() => handleDeleteField(tab.key, fieldKey)}
                            >
                              {t("carUpload.deleteField")}
                            </Button>
                          </Col>
                        </Row>
                      </Form.Group>
                  ))}
                  <Button
                      className={styles.addFieldButton}
                      onClick={() => handleAddField(tab.key)}
                  >
                    {t("carUpload.addField")}
                  </Button>
                </Tab>
            ))}

            <Tab
                eventKey="add"
                title={
                  <div className={styles.addTabButton} onClick={handleAddTab}>
                    +
                  </div>
                }
                tabClassName="add-tab"
                className={styles.addTab}
                style={{padding: "0"}}

            />
          </Tabs>

          <Button variant="primary" type="submit" style={{ marginTop: "20px", width:"140px" }}>
            {id ? t("carUpload.updateButton") : t("carUpload.uploadButton")}
          </Button>
        </Form>
      </div>
  );
};

export default CarUploadPage;
