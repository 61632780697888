import React, { useEffect, useState, useRef } from "react";
import axios from "../../utils/axios";
import { Link } from "react-router-dom";
import { FaThumbsUp } from "react-icons/fa";
import styles from "./PostList.module.css";
import { getDecodedToken } from "../../utils/jwtUtils";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import {formatDate} from "../../utils/formatDate";
import CustomPagenation from "../../utils/CustomPagenation"

const PostList = () => {
    const { t } = useTranslation();
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [searchType, setSearchType] = useState("title");
    const [page, setPage] = useState({});
    const searchKeywordRef = useRef("");
    const [categories, setCategories] = useState([
        "All",
        "FreeBoard",
        "PartsReview",
        "TestDriveReview",
        "QnABoard",
        "InformationBoard",
        "CarNews",
        "UsedCarMarketplace",
        "EventsMeetups",
        "CommunityTipsRecommendations",
        "MechanicAdvice"
    ]);
    const [selectedCategory, setSelectedCategory] = useState("All");

    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getDecodedToken();

    const fetchPosts = async () => {
        setLoading(true);
        try {
            setIsLoggedIn(token !== null);
            const response = await axios.get(`${apiUrl}/api/post`, {
                params: {
                    page: currentPage -1 , // 1 기반에서 0 기반으로 변환
                    size: 10,
                    category: selectedCategory,
                    searchType: searchType,
                    keyword: searchKeywordRef.current,
                    sort: 'id,desc',
                },
            });
            setPosts(response.data.content || []);
            setPage({
                ...response.data.page, // 0 기반에서 1 기반으로 변환
            });
        } catch (error) {
            console.error("Error fetching posts:", error);
            setPosts([]);
            setPage({ number: 0, totalPages: 1 });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPosts();
    }, [currentPage, selectedCategory, searchType]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        setCurrentPage(0);
    };

    const handleSearch = () => {
        setCurrentPage(0);
    };

    const handleInputChange = (event) => {
        searchKeywordRef.current = event.target.value;
    };

    // 국가 코드로 국기 이미지를 가져오는 함수
    const getFlagUrl = (countryCode) => {
        return `https://flagcdn.com/16x12/${countryCode.toLowerCase()}.png`;
    };

    return (
        <div className={styles.contentContainer}>
            <h1 className={`${styles.textCenter} ${styles.mb4}`}>{t('postList.title')}</h1>

            {/* 카테고리 리스트 */}
            <div className={styles.categoryList}>
                {categories.map((category) => (
                    <button
                        key={category}
                        className={`${styles.categoryButton} ${selectedCategory === category ? styles.activeCategory : ""}`}
                        onClick={() => handleCategoryChange(category)}
                    >
                        {t(`postList.categories.${category}`)}
                    </button>
                ))}
            </div>

            {/* 글쓰기 버튼 (로그인된 경우에만 보임) */}
            {isLoggedIn && (
                <Link to="/post/new" className={styles.writeButton}>
                    {t('postList.writePost')}
                </Link>
            )}

            {/* 게시글 목록 헤더 */}
            <div className={styles.postListHeader}>
                <div className={styles.postInfoContainer}>
                    <div className={styles.postInfoLeft}>
                        <span className={styles.postCategory}>{t('postList.header.category')}</span>
                        <span className={styles.postTitle}>{t('postList.header.title')}</span>
                    </div>
                    <div className={styles.postInfoRight}>
                        <span className={styles.postAuthor}>{t('postList.header.author')}</span>
                        <span className={styles.postCountry}>{t('postList.header.country')}</span>
                        <span className={styles.viewCount}>{t('postList.header.views')}</span>
                        <span className={styles.likeCount}>{t('postList.header.likes')}</span>
                        <span className={styles.postDate}>{t('postList.header.date')}</span>
                    </div>
                </div>
            </div>

            {/* 게시글 리스트 */}
            <ul className={styles.postList}>
                {posts.length > 0 ? (
                    posts.map((post) => (
                        <li key={post.id} className={styles.postItem}>
                            <div className={styles.postInfoContainer}>
                                <div className={styles.postInfoLeft}>
                                    <span className={styles.postCategory}>{t(`postList.categories.${post.category}`)}</span>
                                    <Link to={`/post/${post.id}`} className={styles.postTitle}>
                                        {post.title}
                                    </Link>
                                </div>
                                <div className={styles.postInfoRight}>
                                    <span className={styles.postAuthor}>{post.userName}</span>
                                    <span className={styles.postCountry}>
                                         <ReactCountryFlag
                                             countryCode={post.userCountry}
                                             svg
                                             style={{
                                                 width: '25px',
                                                 height: '20px',
                                                 borderRadius: '2px'
                                             }}
                                             title={post.userCountry}
                                         />
                                        {post.userCountry}
                                    </span>
                                    <span className={styles.viewCount}>{post.viewCount} {t('postList.views')}</span>
                                    <span className={styles.likeCount}>
                                        <FaThumbsUp className={styles.likeIcon} />
                                        {post.likeCount}
                                    </span>
                                    <span className={styles.postDate}>
                                        {formatDate(post.createdAt)}
                                    </span>
                                </div>
                            </div>
                        </li>
                    ))
                ) : (
                    <li className={styles.emptyPostItem}>
                        <div>{t('postList.noPosts')}</div>
                    </li>
                )}
            </ul>

            {/* 검색바 */}
            <div className={styles.searchContainer}>
                <select
                    value={searchType}
                    onChange={(e) => setSearchType(e.target.value)}
                >
                    <option value="title">{t('postList.search.title')}</option>
                    <option value="author">{t('postList.search.author')}</option>
                    <option value="content">{t('postList.search.content')}</option>
                </select>
                <input
                    type="text"
                    defaultValue={searchKeywordRef.current}
                    onChange={handleInputChange}
                    placeholder={t('postList.search.placeholder')}
                />
                <button onClick={handleSearch}>{t('postList.search.button')}</button>
            </div>

            <CustomPagenation page={page} handleActivePage={handlePageChange}/>
        </div>
    );
};

export default PostList;
