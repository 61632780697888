import React, {useEffect, useState} from "react";
import {Modal, Button, Form, Row, Col} from "react-bootstrap";
import {useDispatch} from 'react-redux';
import axios from '../../utils/axios';
import {login, setUser} from '../../store/Store';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {GoogleLogin} from '@react-oauth/google';
import {getDecodedToken} from "../../utils/jwtUtils";
import styles from "./LoginModal.module.css"
import { useTranslation } from "react-i18next";

function decodeBase64Url(base64Url) {
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(decodeURIComponent(escape(window.atob(base64))));
}

const LoginModal = ({show, handleClose, handleSignupShow, handleForgotPasswordShow}) => {
    const { t } = useTranslation(); // 번역 훅
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const apiUrl = process.env.REACT_APP_API_URL;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (show) {
            setEmail("");
            setPassword("");
            setErrorMessage("");
        }
    }, [show]);

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${apiUrl}/auth/login`, {
                email,
                password,
            });

            if (response.status === 200) {
                const {token} = response.data;
                localStorage.setItem('token', token);

                const decodedToken = decodeBase64Url(token.split('.')[1]);
                const userData = {name: decodedToken.name, role: decodedToken.role};

                dispatch(login(userData));
                dispatch(setUser(userData));
                toast.success('로그인에 성공했습니다!');
                setEmail("");
                setPassword("");
                handleClose();
                navigate('/home');
            }
        } catch (error) {
            setErrorMessage('로그인에 실패했습니다.');
        }
    };

    const handleGoogleLogin = async (credentialResponse) => {

        const token = credentialResponse.credential;
        try{
            const response = await axios.post(`${apiUrl}/auth/google`, token);
            console.log(response);
            if (response.status === 200) {
                const {token} = response.data;
                localStorage.setItem('token', token);

                const decodedToken = decodeBase64Url(token.split('.')[1]);
                const userData = {name: decodedToken.name, role: decodedToken.role};

                dispatch(login(userData));
                dispatch(setUser(userData));
                toast.success('로그인에 성공했습니다!');
                handleClose();
                navigate('/');
            }
        }catch (e) {
            setErrorMessage('로그인에 실패했습니다.');
        }

    };

    const handleError = () => {
        setErrorMessage('로그인에 실패했습니다. 다시 시도해 주세요.');
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t("loginModal.title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleLogin}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>{t("loginModal.emailLabel")}</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder={t("loginModal.emailPlaceholder")}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>{t("loginModal.passwordLabel")}</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder={t("loginModal.passwordPlaceholder")}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </Form.Group>
                    {errorMessage && <div className="text-danger mt-2">{t("loginModal.errorMessage")}</div>}
                    <Button variant="primary" type="submit" className="w-100 mt-3">{t("loginModal.loginButton")}</Button>
                </Form>

                <hr/>

                <div className="mt-3">
                    <Row className="mb-2">
                        <Col xs={12} className={`mb-2 ${styles.centerAlign}`}>
                            <GoogleLogin
                                onSuccess={handleGoogleLogin}
                                onError={handleError}
                            />
                        </Col>
                    </Row>
                    {/* LinkedIn, Facebook, WeChat 버튼 코드 생략 */}
                </div>

                <div className="mt-3 text-center">
                    <a href="#signup" onClick={() => {
                        handleClose();
                        handleSignupShow();
                    }}>{t("loginModal.signupLink")}</a>{" "}
                    |{" "}
                    <a href="#forgot-password" onClick={() => {
                        handleClose();
                        handleForgotPasswordShow();
                    }}>
                        {t("loginModal.forgotPasswordLink")}
                    </a>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default LoginModal;
