import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./ContactForm.css";
import { useTranslation } from 'react-i18next';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        inquiryContent: "",
        country: "", // 국가 추가
    });
    const { t } = useTranslation();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handlePhoneChange = (value) => {
        setFormData({
            ...formData,
            phone: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // 제출 로직 추가
        console.log("Form Data:", formData);
    };

    return (
        <form className="contact-form" onSubmit={handleSubmit}>
            <h1>{t('contactForm.title')}</h1>
            <div className="form-group">
                <label htmlFor="name">{t('contactForm.name')}</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="form-group">
                <label htmlFor="email">{t('contactForm.email')}</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
            </div>
            {/*<div className="form-group">*/}
            {/*    <label htmlFor="phone">전화번호</label>*/}
            {/*    <PhoneInput*/}
            {/*        country={"kr"}*/}
            {/*        value={formData.phone}*/}
            {/*        onChange={handlePhoneChange}*/}
            {/*        inputProps={{*/}
            {/*            name: "phone",*/}
            {/*            required: true,*/}
            {/*            autoFocus: false,*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</div>*/}
            <div className="form-group">
                <label htmlFor="inquiryContent">{t('contactForm.inquiryContent')}</label>
                <textarea
                    id="inquiryContent"
                    name="inquiryContent"
                    value={formData.inquiryContent}
                    onChange={handleChange}
                    required
                />
            </div>
            <button type="submit">{t('contactForm.submit')}</button>
        </form>
    );
};

export default ContactForm;
