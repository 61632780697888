export const countries = [
    { value: "AF", label: "Afghanistan (افغانستان)", flag: "AF", continent: "Asia" },
    { value: "AL", label: "Albania (Shqipëri)", flag: "AL", continent: "Europe" },
    { value: "DZ", label: "Algeria (الجزائر)", flag: "DZ", continent: "Africa" },
    { value: "AR", label: "Argentina (Argentina)", flag: "AR", continent: "South America" },
    { value: "AU", label: "Australia (Australia)", flag: "AU", continent: "Oceania" },
    { value: "AT", label: "Austria (Österreich)", flag: "AT", continent: "Europe" },
    { value: "AZ", label: "Azerbaijan (Azərbaycan)", flag: "AZ", continent: "Asia" },
    { value: "BH", label: "Bahrain (البحرين)", flag: "BH", continent: "Asia" },
    { value: "BD", label: "Bangladesh (বাংলাদেশ)", flag: "BD", continent: "Asia" },
    { value: "BY", label: "Belarus (Беларусь)", flag: "BY", continent: "Europe" },
    { value: "BE", label: "Belgium (België)", flag: "BE", continent: "Europe" },
    { value: "BZ", label: "Belize (Belize)", flag: "BZ", continent: "North America" },
    { value: "BJ", label: "Benin (Bénin)", flag: "BJ", continent: "Africa" },
    { value: "BT", label: "Bhutan (འབྲུག)", flag: "BT", continent: "Asia" },
    { value: "BO", label: "Bolivia (Bolivia)", flag: "BO", continent: "South America" },
    { value: "BR", label: "Brazil (Brasil)", flag: "BR", continent: "South America" },
    { value: "BN", label: "Brunei (بروني)", flag: "BN", continent: "Asia" },
    { value: "BG", label: "Bulgaria (България)", flag: "BG", continent: "Europe" },
    { value: "BF", label: "Burkina Faso (Burkina Faso)", flag: "BF", continent: "Africa" },
    { value: "BI", label: "Burundi (Burundi)", flag: "BI", continent: "Africa" },
    { value: "KH", label: "Cambodia (កម្ពុជា)", flag: "KH", continent: "Asia" },
    { value: "CM", label: "Cameroon (Cameroun)", flag: "CM", continent: "Africa" },
    { value: "CA", label: "Canada (Canada)", flag: "CA", continent: "North America" },
    { value: "CV", label: "Cape Verde (Cabo Verde)", flag: "CV", continent: "Africa" },
    { value: "CF", label: "Central African Republic (République centrafricaine)", flag: "CF", continent: "Africa" },
    { value: "TD", label: "Chad (تشاد)", flag: "TD", continent: "Africa" },
    { value: "CL", label: "Chile (Chile)", flag: "CL", continent: "South America" },
    { value: "CN", label: "China (中国)", flag: "CN", continent: "Asia" },
    { value: "CO", label: "Colombia (Colombia)", flag: "CO", continent: "South America" },
    { value: "KM", label: "Comoros (Komori)", flag: "KM", continent: "Africa" },
    { value: "CG", label: "Congo (République du Congo)", flag: "CG", continent: "Africa" },
    { value: "CD", label: "Congo (Democratic Republic)", flag: "CD", continent: "Africa" },
    { value: "CR", label: "Costa Rica (Costa Rica)", flag: "CR", continent: "North America" },
    { value: "CI", label: "Côte d'Ivoire (Côte d'Ivoire)", flag: "CI", continent: "Africa" },
    { value: "HR", label: "Croatia (Hrvatska)", flag: "HR", continent: "Europe" },
    { value: "CU", label: "Cuba (Cuba)", flag: "CU", continent: "North America" },
    { value: "CY", label: "Cyprus (Κύπρος)", flag: "CY", continent: "Asia" },
    { value: "CZ", label: "Czech Republic (Česko)", flag: "CZ", continent: "Europe" },
    { value: "DK", label: "Denmark (Danmark)", flag: "DK", continent: "Europe" },
    { value: "DJ", label: "Djibouti (Djibouti)", flag: "DJ", continent: "Africa" },
    { value: "DM", label: "Dominica (Dominica)", flag: "DM", continent: "North America" },
    { value: "DO", label: "Dominican Republic (República Dominicana)", flag: "DO", continent: "North America" },
    { value: "EC", label: "Ecuador (Ecuador)", flag: "EC", continent: "South America" },
    { value: "EG", label: "Egypt (مصر)", flag: "EG", continent: "Africa" },
    { value: "SV", label: "El Salvador (El Salvador)", flag: "SV", continent: "North America" },
    { value: "GQ", label: "Equatorial Guinea (Guinea Ecuatorial)", flag: "GQ", continent: "Africa" },
    { value: "ER", label: "Eritrea (Eritrea)", flag: "ER", continent: "Africa" },
    { value: "EE", label: "Estonia (Eesti)", flag: "EE", continent: "Europe" },
    { value: "ET", label: "Ethiopia (Ethiopia)", flag: "ET", continent: "Africa" },
    { value: "FJ", label: "Fiji (Fiji)", flag: "FJ", continent: "Oceania" },
    { value: "FI", label: "Finland (Suomi)", flag: "FI", continent: "Europe" },
    { value: "FR", label: "France (France)", flag: "FR", continent: "Europe" },
    { value: "GA", label: "Gabon (Gabon)", flag: "GA", continent: "Africa" },
    { value: "GM", label: "Gambia (Gambia)", flag: "GM", continent: "Africa" },
    { value: "GE", label: "Georgia (საქართველო)", flag: "GE", continent: "Asia" },
    { value: "DE", label: "Germany (Deutschland)", flag: "DE", continent: "Europe" },
    { value: "GH", label: "Ghana (Ghana)", flag: "GH", continent: "Africa" },
    { value: "GR", label: "Greece (Ελλάδα)", flag: "GR", continent: "Europe" },
    { value: "GT", label: "Guatemala (Guatemala)", flag: "GT", continent: "North America" },
    { value: "GN", label: "Guinea (Guinée)", flag: "GN", continent: "Africa" },
    { value: "GW", label: "Guinea-Bissau (Guiné-Bissau)", flag: "GW", continent: "Africa" },
    { value: "GY", label: "Guyana (Guyana)", flag: "GY", continent: "South America" },
    { value: "HT", label: "Haiti (Haïti)", flag: "HT", continent: "North America" },
    { value: "HN", label: "Honduras (Honduras)", flag: "HN", continent: "North America" },
    { value: "HU", label: "Hungary (Magyarország)", flag: "HU", continent: "Europe" },
    { value: "IS", label: "Iceland (Ísland)", flag: "IS", continent: "Europe" },
    { value: "IN", label: "India (भारत)", flag: "IN", continent: "Asia" },
    { value: "ID", label: "Indonesia (Indonesia)", flag: "ID", continent: "Asia" },
    { value: "IR", label: "Iran (ایران)", flag: "IR", continent: "Asia" },
    { value: "IQ", label: "Iraq (العراق)", flag: "IQ", continent: "Asia" },
    { value: "IE", label: "Ireland (Éire)", flag: "IE", continent: "Europe" },
    { value: "IL", label: "Israel (ישראל)", flag: "IL", continent: "Asia" },
    { value: "IT", label: "Italy (Italia)", flag: "IT", continent: "Europe" },
    { value: "JM", label: "Jamaica (Jamaica)", flag: "JM", continent: "North America" },
    { value: "JP", label: "Japan (日本)", flag: "JP", continent: "Asia" },
    { value: "JO", label: "Jordan (الأردن)", flag: "JO", continent: "Asia" },
    { value: "KZ", label: "Kazakhstan (Қазақстан)", flag: "KZ", continent: "Asia" },
    { value: "KE", label: "Kenya (Kenya)", flag: "KE", continent: "Africa" },
    { value: "KI", label: "Kiribati (Kiribati)", flag: "KI", continent: "Oceania" },
    { value: "KP", label: "North Korea (북한)", flag: "KP", continent: "Asia" },
    { value: "KR", label: "South Korea (대한민국)", flag: "KR", continent: "Asia" },
    { value: "KW", label: "Kuwait (الكويت)", flag: "KW", continent: "Asia" },
    { value: "KG", label: "Kyrgyzstan (Кыргызстан)", flag: "KG", continent: "Asia" },
    { value: "LA", label: "Laos (ລາວ)", flag: "LA", continent: "Asia" },
    { value: "LV", label: "Latvia (Latvija)", flag: "LV", continent: "Europe" },
    { value: "LB", label: "Lebanon (لبنان)", flag: "LB", continent: "Asia" },
    { value: "LS", label: "Lesotho (Lesotho)", flag: "LS", continent: "Africa" },
    { value: "LR", label: "Liberia (Liberia)", flag: "LR", continent: "Africa" },
    { value: "LY", label: "Libya (ليبيا)", flag: "LY", continent: "Africa" },
    { value: "LI", label: "Liechtenstein (Liechtenstein)", flag: "LI", continent: "Europe" },
    { value: "LT", label: "Lithuania (Lietuva)", flag: "LT", continent: "Europe" },
    { value: "LU", label: "Luxembourg (Luxembourg)", flag: "LU", continent: "Europe" },
    { value: "MG", label: "Madagascar (Madagasikara)", flag: "MG", continent: "Africa" },
    { value: "MW", label: "Malawi (Malawi)", flag: "MW", continent: "Africa" },
    { value: "MY", label: "Malaysia (Malaysia)", flag: "MY", continent: "Asia" },
    { value: "MV", label: "Maldives (Maldives)", flag: "MV", continent: "Asia" },
    { value: "ML", label: "Mali (Mali)", flag: "ML", continent: "Africa" },
    { value: "MT", label: "Malta (Malta)", flag: "MT", continent: "Europe" },
    { value: "MH", label: "Marshall Islands (Marshall Islands)", flag: "MH", continent: "Oceania" },
    { value: "MR", label: "Mauritania (موريتانيا)", flag: "MR", continent: "Africa" },
    { value: "MU", label: "Mauritius (Mauritius)", flag: "MU", continent: "Africa" },
    { value: "MX", label: "Mexico (México)", flag: "MX", continent: "North America" },
    { value: "FM", label: "Micronesia (Micronesia)", flag: "FM", continent: "Oceania" },
    { value: "MD", label: "Moldova (Republica Moldova)", flag: "MD", continent: "Europe" },
    { value: "MC", label: "Monaco (Monaco)", flag: "MC", continent: "Europe" },
    { value: "MN", label: "Mongolia (Монгол)", flag: "MN", continent: "Asia" },
    { value: "ME", label: "Montenegro (Crna Gora)", flag: "ME", continent: "Europe" },
    { value: "MA", label: "Morocco (المغرب)", flag: "MA", continent: "Africa" },
    { value: "MZ", label: "Mozambique (Moçambique)", flag: "MZ", continent: "Africa" },
    { value: "MM", label: "Myanmar (Burma) (မြန်မာ)", flag: "MM", continent: "Asia" },
    { value: "NA", label: "Namibia (Namibia)", flag: "NA", continent: "Africa" },
    { value: "NR", label: "Nauru (Nauru)", flag: "NR", continent: "Oceania" },
    { value: "NP", label: "Nepal (नेपाल)", flag: "NP", continent: "Asia" },
    { value: "NL", label: "Netherlands (Nederland)", flag: "NL", continent: "Europe" },
    { value: "NZ", label: "New Zealand (New Zealand)", flag: "NZ", continent: "Oceania" },
    { value: "NI", label: "Nicaragua (Nicaragua)", flag: "NI", continent: "North America" },
    { value: "NE", label: "Niger (Niger)", flag: "NE", continent: "Africa" },
    { value: "NG", label: "Nigeria (Nigeria)", flag: "NG", continent: "Africa" },
    { value: "NO", label: "Norway (Norge)", flag: "NO", continent: "Europe" },
    { value: "OM", label: "Oman (عمان)", flag: "OM", continent: "Asia" },
    { value: "PK", label: "Pakistan (پاکستان)", flag: "PK", continent: "Asia" },
    { value: "PW", label: "Palau (Palau)", flag: "PW", continent: "Oceania" },
    { value: "PA", label: "Panama (Panamá)", flag: "PA", continent: "North America" },
    { value: "PG", label: "Papua New Guinea (Papua New Guinea)", flag: "PG", continent: "Oceania" },
    { value: "PY", label: "Paraguay (Paraguay)", flag: "PY", continent: "South America" },
    { value: "PE", label: "Peru (Perú)", flag: "PE", continent: "South America" },
    { value: "PH", label: "Philippines (Pilipinas)", flag: "PH", continent: "Asia" },
    { value: "PL", label: "Poland (Polska)", flag: "PL", continent: "Europe" },
    { value: "PT", label: "Portugal (Portugal)", flag: "PT", continent: "Europe" },
    { value: "QA", label: "Qatar (قطر)", flag: "QA", continent: "Asia" },
    { value: "RO", label: "Romania (România)", flag: "RO", continent: "Europe" },
    { value: "RU", label: "Russia (Россия)", flag: "RU", continent: "Europe" },
    { value: "RW", label: "Rwanda (Rwanda)", flag: "RW", continent: "Africa" },
    { value: "KN", label: "Saint Kitts and Nevis (Saint Kitts and Nevis)", flag: "KN", continent: "North America" },
    { value: "LC", label: "Saint Lucia (Saint Lucia)", flag: "LC", continent: "North America" },
    { value: "VC", label: "Saint Vincent and the Grenadines (Saint Vincent and the Grenadines)", flag: "VC", continent: "North America" },
    { value: "WS", label: "Samoa (Samoa)", flag: "WS", continent: "Oceania" },
    { value: "SM", label: "San Marino (San Marino)", flag: "SM", continent: "Europe" },
    { value: "ST", label: "São Tomé and Príncipe (São Tomé and Príncipe)", flag: "ST", continent: "Africa" },
    { value: "SA", label: "Saudi Arabia (المملكة العربية السعودية)", flag: "SA", continent: "Asia" },
    { value: "SN", label: "Senegal (Sénégal)", flag: "SN", continent: "Africa" },
    { value: "RS", label: "Serbia (Србија)", flag: "RS", continent: "Europe" },
    { value: "SC", label: "Seychelles (Seychelles)", flag: "SC", continent: "Africa" },
    { value: "SL", label: "Sierra Leone (Sierra Leone)", flag: "SL", continent: "Africa" },
    { value: "SG", label: "Singapore (新加坡)", flag: "SG", continent: "Asia" },
    { value: "SK", label: "Slovakia (Slovensko)", flag: "SK", continent: "Europe" },
    { value: "SI", label: "Slovenia (Slovenija)", flag: "SI", continent: "Europe" },
    { value: "SB", label: "Solomon Islands (Solomon Islands)", flag: "SB", continent: "Oceania" },
    { value: "SO", label: "Somalia (Soomaaliya)", flag: "SO", continent: "Africa" },
    { value: "ZA", label: "South Africa (South Africa)", flag: "ZA", continent: "Africa" },
    { value: "ES", label: "Spain (España)", flag: "ES", continent: "Europe" },
    { value: "LK", label: "Sri Lanka (ශ්‍රී ලංකාව)", flag: "LK", continent: "Asia" },
    { value: "SD", label: "Sudan (السودان)", flag: "SD", continent: "Africa" },
    { value: "SR", label: "Suriname (Suriname)", flag: "SR", continent: "South America" },
    { value: "SZ", label: "Eswatini (Swaziland)", flag: "SZ", continent: "Africa" },
    { value: "SE", label: "Sweden (Sverige)", flag: "SE", continent: "Europe" },
    { value: "CH", label: "Switzerland (Schweiz)", flag: "CH", continent: "Europe" },
    { value: "SY", label: "Syria (سوريا)", flag: "SY", continent: "Asia" },
    { value: "TW", label: "Taiwan (台灣)", flag: "TW", continent: "Asia" },
    { value: "TJ", label: "Tajikistan (Тоҷикистон)", flag: "TJ", continent: "Asia" },
    { value: "TZ", label: "Tanzania (Tanzania)", flag: "TZ", continent: "Africa" },
    { value: "TH", label: "Thailand (ประเทศไทย)", flag: "TH", continent: "Asia" },
    { value: "TL", label: "Timor-Leste (Timor-Leste)", flag: "TL", continent: "Asia" },
    { value: "TG", label: "Togo (Togo)", flag: "TG", continent: "Africa" },
    { value: "TO", label: "Tonga (Tonga)", flag: "TO", continent: "Oceania" },
    { value: "TT", label: "Trinidad and Tobago (Trinidad and Tobago)", flag: "TT", continent: "North America" },
    { value: "TN", label: "Tunisia (تونس)", flag: "TN", continent: "Africa" },
    { value: "TR", label: "Turkey (Türkiye)", flag: "TR", continent: "Asia" },
    { value: "TM", label: "Turkmenistan (Türkmenistan)", flag: "TM", continent: "Asia" },
    { value: "TV", label: "Tuvalu (Tuvalu)", flag: "TV", continent: "Oceania" },
    { value: "UG", label: "Uganda (Uganda)", flag: "UG", continent: "Africa" },
    { value: "UA", label: "Ukraine (Україна)", flag: "UA", continent: "Europe" },
    { value: "AE", label: "United Arab Emirates (الإمارات)", flag: "AE", continent: "Asia" },
    { value: "GB", label: "United Kingdom (United Kingdom)", flag: "GB", continent: "Europe" },
    { value: "US", label: "United States (United States)", flag: "US", continent: "North America" },
    { value: "UY", label: "Uruguay (Uruguay)", flag: "UY", continent: "South America" },
    { value: "UZ", label: "Uzbekistan (Oʻzbekiston)", flag: "UZ", continent: "Asia" },
    { value: "VU", label: "Vanuatu (Vanuatu)", flag: "VU", continent: "Oceania" },
    { value: "VE", label: "Venezuela (Venezuela)", flag: "VE", continent: "South America" },
    { value: "VN", label: "Vietnam (Việt Nam)", flag: "VN", continent: "Asia" },
    { value: "YE", label: "Yemen (اليمن)", flag: "YE", continent: "Asia" },
    { value: "ZM", label: "Zambia (Zambia)", flag: "ZM", continent: "Africa" },
    { value: "ZW", label: "Zimbabwe (Zimbabwe)", flag: "ZW", continent: "Africa" }
];
